import { shouldSearchByHashtag } from '../services/search-utils';
import { extractHashtagsFromText } from '@wix/communities-forum-client-commons/dist/src/services/hashtag-utils';
import uri from '@wix/communities-forum-client-commons/dist/src/services/urijs';
import { decodeQuery } from '../services/query-encoding';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import {
  ApiMetadata,
  CommonRequest,
  SearchResultComment,
  SearchResultPost,
  SearchSuggestionsData,
} from '../types';

type SearchData = {
  metadata: ApiMetadata;
  posts: (SearchResultPost | SearchResultComment)[];
};

export const fetchSearchSuggestionsPromisified = createPromisifiedAction(
  (query: string, limit: number) => {
    return (
      _dispatch: any,
      _getState: any,
      { request }: { request: CommonRequest },
    ): Promise<SearchSuggestionsData> => {
      const pagination = {
        size: limit,
        offset: 0,
      };
      const originalQuery = query;
      query = decodeQuery(decodeURIComponent(query));

      const path = shouldSearchByHashtag(query)
        ? uri('/search/hashtags', '').query({
            q: extractHashtagsFromText(query).join(','),
            ...pagination,
          })
        : uri('/search', '').query({ q: query, ...pagination });

      return request<SearchData>(path).then(forumSearchData => {
        return {
          query: originalQuery,
          forumSearchData: {
            entities: forumSearchData.posts,
            totalCount: forumSearchData.metadata.total,
          },
        };
      });
    };
  },
  ((data: SearchSuggestionsData) => data) as any,
);
