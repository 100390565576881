import createAction from '../services/create-action';
import { getTranslations } from '../store/translations/translations-selectors';
import { setTranslations } from '../store/translations/translations-actions';
import abTranslate from 'ab-translate/dist/src/abTranslate-runtime';
import { EXPERIMENT_WIX_COMMENTS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { isEditor } from '../store/basic-params/basic-params-selectors';

export const FETCH_EXPERIMENTS_REQUEST = 'experiments/FETCH_EXPERIMENTS_REQUEST';
export const FETCH_EXPERIMENTS_SUCCESS = 'experiments/FETCH_EXPERIMENTS_SUCCESS';
export const FETCH_EXPERIMENTS_FAILURE = 'experiments/FETCH_EXPERIMENTS_FAILURE';

export const fetchExperimentsRequest = createAction(FETCH_EXPERIMENTS_REQUEST);
export const fetchExperimentsSuccess = createAction(FETCH_EXPERIMENTS_SUCCESS);
export const fetchExperimentsFailure = createAction(FETCH_EXPERIMENTS_FAILURE);

export function fetchExperiments(experimentsBag = {}) {
  return (dispatch, getState, { wixCommentsExperimentApi }) => {
    dispatch(fetchExperimentsRequest());

    let commentsExperimentPromise = Promise.resolve();

    if (!isEditor(getState()) && wixCommentsExperimentApi) {
      commentsExperimentPromise = wixCommentsExperimentApi('/enabled').then(isEnabled =>
        dispatch(fetchExperimentsSuccess({ [EXPERIMENT_WIX_COMMENTS]: `${isEnabled}` })),
      );
    }

    return Promise.all([commentsExperimentPromise]).then(
      () => {
        const currentTranslations = getTranslations(getState());
        const translationsWithExperiments = abTranslate(experimentsBag, currentTranslations);
        dispatch(setTranslations(translationsWithExperiments));
        return dispatch(fetchExperimentsSuccess(experimentsBag));
      },
      () => dispatch(fetchExperimentsFailure()),
    );
  };
}
