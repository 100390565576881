import createAction from '../../common/services/create-action';

export const MARK_POST_AS_READ_REQUEST = 'post/MARK_POST_AS_READ_REQUEST';
export const MARK_POST_AS_READ_SUCCESS = 'post/MARK_POST_AS_READ_SUCCESS';
export const MARK_POST_AS_READ_FAILURE = 'post/MARK_POST_AS_READ_FAILURE';

const markPostAsReadRequest = createAction(MARK_POST_AS_READ_REQUEST);
const markPostAsReadSuccess = createAction(MARK_POST_AS_READ_SUCCESS);
const markPostAsReadFailure = createAction(MARK_POST_AS_READ_FAILURE);

export function markPostAsRead(postId) {
  return (dispatch, getState, { request }) => {
    dispatch(markPostAsReadRequest({ postId }));

    const promise = request.put(`/platformized/v1/posts/${postId}/mark-read`);
    return promise
      .then(
        () => dispatch(markPostAsReadSuccess({ postId })),
        error => dispatch(markPostAsReadFailure({ postId }, error)),
      )
      .then(() => promise);
  };
}
