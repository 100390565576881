import createAction from '../../common/services/create-action';
import { getCurrentInstance } from '../../common/services/get-instance';

export const FETCH_BADGES_REQUEST = 'badges/FETCH_REQUEST';
export const FETCH_BADGES_SUCCESS = 'badges/FETCH_SUCCESS';
export const FETCH_BADGES_FAILURE = 'badges/FETCH_FAILURE';

export const fetchBadgesRequest = createAction(FETCH_BADGES_REQUEST);
export const fetchBadgesSuccess = createAction(FETCH_BADGES_SUCCESS, payload => payload);
export const fetchBadgesFailure = createAction(FETCH_BADGES_FAILURE);

export const fetchBadges = () => {
  return (dispatch, getState, { badgesRequest }) => {
    dispatch(fetchBadgesRequest());
    const config = {
      headers: {
        instance: null,
      },
    };
    const promise = badgesRequest('/v1/badges', config);

    return promise.then(
      res => dispatch(fetchBadgesSuccess(res?.badges || [])),
      () => dispatch(fetchBadgesFailure()),
    );
  };
};
